@import "react-datepicker/dist/react-datepicker.css";

.pagination {
  font-size: 12px;
}

.pagination > ul,
li {
  list-style: none;
  display: inline;
  padding-left: 5px;
}

.pagination > ul,
li > a {
  background-color: white;
}

.pagination > li:hover:not(.disabled) a {
  color: #ffffff;
  background-color: #4a5568;
  border: solid 1px #4a5568;
}

.pagination > li.active a {
  color: #ffffff;
  background-color: #4a5568;
  border: solid 1px #4a5568;
}

.pagination > li.disabled {
  cursor: not-allowed;
}

.pagination > li.disabled a {
  pointer-events: none;
}

.pagination > li:first-child {
  float: left;
}

.pagination > li:last-child {
  float: right;
}

.pagination > li a {
  border: solid 1px #edf2f7;
  border-radius: 0.2rem;
  color: #4a5568;
  text-decoration: none;
  text-transform: capitalize;
  display: inline-block;
  text-align: center;
  padding: 0.2rem 0.7rem;
}

.codemirror-container {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.codemirror-container > div {
  height: 100%;
  width: 100%;
}

.codemirror-editor {
  height: 100%;
}

.codemirror-input > .codemirror-container > div {
  border-radius: 7px;
}

.codemirror-rounded-left > .ͼ1.cm-editor {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border: white;
}

.ͼ1.cm-editor {
  border: solid 1px #e2e8f0;
}

.input-cm .ͼ1 .cm-content {
  line-height: 30px;
}

.input-cm .ͼ1 .cm-line {
  font-family: var(--chakra-fonts-body);
}

.readonly-code-cm .ͼ1 .cm-content {
  padding: 10px;
  line-height: 20px;
}

.readonly-code-cm .ͼ1.cm-editor {
  background: #e1e7eb;
  overflow-y: hidden;
  color: #1c2b47;
}

.readonly-code-cm .ͼ1 .cm-scroller {
  overflow-y: hidden;
}

.readonly-code-cm .ͼ4 .cm-line {
  text-wrap: wrap;
}

.readonly-code-cm .ͼ2 .cm-activeLine {
  background-color: #e1e7eb;
}

.ͼ1.cm-editor.cm-focused {
  box-shadow: 0 0 0 1px #3182ce !important;
  border-color: #3182ce !important;
  outline: unset;
}

.codemirror-container .highlight {
  background-color: rgba(43, 232, 197, 0.502);
}

.codemirror-container .highlight2 {
  background-color: rgba(31, 240, 72, 0.502);
}

.codemirror-container .highlight3 {
  background-color: rgba(238, 150, 43, 0.851);
}

.codemirror-container .ͼ1 .cm-tooltip.cm-tooltip-autocomplete > ul {
  display: flex;
  flex-direction: column;
  max-width: min(700px, 95vw);
  max-height: 80px;
}

.ͼ1 .cm-tooltip.cm-tooltip-autocomplete > ul > li {
  overflow-x: unset !important;
  min-height: 20px;
}

.codemirror-container .ͼ1 .cm-completionLabel {
  margin-left: 5px;
}

.codemirror-container .cm-diagnostic-error {
  border: 1px solid #e53e3e;
  background: white;
  color: #e53e3e;
  padding: 0 15px;
  font-size: 12px;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  12.5% {
    transform: translateX(4px);
  }

  25% {
    transform: translateX(0px);
  }

  37.5% {
    transform: translateX(-4px);
  }

  50% {
    transform: translateX(0);
  }

  62.5% {
    transform: translateX(4px);
  }

  75% {
    transform: translateX(0);
  }

  87.5% {
    transform: translateX(-4px);
  }

  100% {
    transform: translateX(0);
  }
}

.disabled-edit {
  opacity: 0.7;
  cursor: not-allowed !important;
}

.disabled-edit > * {
  pointer-events: none;
}

.disabled-edit .widget-container {
  pointer-events: none !important;
  opacity: 0.7;
}

.goto-non-candidate {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.goto-non-candidate > * {
  pointer-events: none !important;
}

.goto-candidate {
  cursor: pointer !important;
}

.goto-candidate > * {
  pointer-events: none !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  background-color: white;
  border-radius: 4px;
}

/* hiding the watermark */
.react-flow__attribution {
  display: none;
}

.react-flow__node.parent {
  z-index: -1 !important;
}

.react-flow__handle {
  background-color: transparent !important;
  border-color: transparent !important;
  min-height: 0px !important;
  min-width: 0px !important;
  height: 0px !important;
  width: 0px !important;
  border-radius: 0 !important;
  pointer-events: all !important;
}

.goto-handle.react-flow__handle {
  background-color: transparent !important;
  border-color: transparent !important;
  height: 1px !important;
  width: 1px !important;
  min-height: 1px !important;
  min-width: 1px !important;
}

.goto-handle.react-flow__handle.react-flow__handle-top {
  top: 0px;
}

.goto-handle.react-flow__handle.react-flow__handle-bottom {
  bottom: 0px;
}

.goto-handle .react-flow__handle.react-flow__handle-right,
.goto-handle .react-flow__handle.react-flow__handle-left {
  background-color: transparent !important;
  border-color: transparent !important;
  height: 2px !important;
  width: 2px !important;
}

.grouping-goto-handle.react-flow__handle.react-flow__handle-right,
.grouping-goto-handle.react-flow__handle.react-flow__handle-left {
  background-color: transparent !important;
  border-color: transparent !important;
  height: 2px !important;
  width: 2px !important;
  top: 30px !important;
}

.exit-criteria-handle.react-flow__handle.react-flow__handle-right,
.exit-criteria-handle.react-flow__handle.react-flow__handle-left {
  background-color: transparent !important;
  border-color: transparent !important;
}

.codemirror-normal-input .cm-activeLine {
  background-color: white;
}

.codemirror-normal-input .cm-line {
  padding-left: 12px;
  font-family: var(--chakra-fonts-body);
}

.codemirror-wrapper .cm-activeLine {
  background-color: white;
}

.goto-edge:focus .react-flow__edge-path,
.goto-edge:focus-visible .react-flow__edge-path,
.goto-edge:hover .react-flow__edge-path {
  stroke-width: 4 !important;
  stroke: #3182ce !important;
}

.goto-edge:hover path {
  stroke-width: 4 !important;
  stroke: #3182ce !important;
}

.exit-edge .react-flow__edge-path,
.exit-edge .react-flow__arrowhead {
  stroke-width: 6 !important;
  stroke: #8d9ebc !important;
}

.exit-edge:focus .react-flow__edge-path,
.exit-edge:focus-visible .react-flow__edge-path,
.exit-edge:hover .react-flow__edge-path,
.exit-edge.react-flow__edge.selected .react-flow__edge-path {
  stroke-width: 6 !important;
  stroke: #ddaf3a !important;
}

.exit-edge:hover path {
  stroke-width: 6 !important;
  stroke: #ddaf3a !important;
}

.react-flow__arrowhead {
  stroke: #3182ce !important;
}

.codemirror-tag-input .ͼ1.cm-editor {
  border-color: white;
}

.codemirror-tag-input .cm-editor.cm-focused {
  border-color: white !important;
  outline: none;
  box-shadow: none !important;
}

.react-flow {
  background: #c1cdd6;
}

.rdrStaticRangeLabel {
  padding: 6px 12px;
}

.rdrDefinedRangesWrapper {
  width: 150px;
  height: 360px;
  border-right-color: #edf2f7;
}

.rdrDateRangePickerWrapper {
  height: 200px;
  border-radius: 10px;
}

.rdrMonth {
  width: 300px;
  padding: 2px 8px;
}

.rdrDay {
  height: 2.65em;
}

.react-datepicker-popper {
  z-index: 2;
}

/*flow skeleton css*/

.tree {
  animation: 0.8s linear 0s infinite alternate none running fadeIn;
}

.tree .tree-layer {
  padding-top: 20px;
  position: relative;
}

.tree .tree-node-wrapper {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
}

/*We will use ::before and ::after to draw the connectors*/

.tree .tree-node-wrapper::before,
.tree .tree-node-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 3px solid #e1e7eb;
  width: 50%;
  height: 20px;
}

.tree .tree-node-wrapper::after {
  right: auto;
  left: 50%;
  border-left: 3px solid #e1e7eb;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree .tree-node-wrapper:only-child::after,
.tree .tree-node-wrapper:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree .tree-node-wrapper:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and 
right connector from last child*/
.tree .tree-node-wrapper:first-child::before,
.tree .tree-node-wrapper:last-child::after {
  border: 0 none;
}

/*Adding back the vertical connector to the last nodes*/
.tree .tree-node-wrapper:last-child::before {
  border-right: 3px solid #e1e7eb;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.tree .tree-node-wrapper:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree .tree-layer .tree-layer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 3px solid #e1e7eb;
  width: 0;
  height: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  12.5% {
    opacity: 0.125;
  }

  25% {
    opacity: 0.25;
  }

  37.5% {
    opacity: 0.375;
  }

  50% {
    opacity: 0.5;
  }

  62.5% {
    opacity: 0.625;
  }

  75% {
    opacity: 0.75;
  }

  87.5% {
    opacity: 0.875;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(75px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-32px);
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes growShrink {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
}

.chakra-slide {
  transition: 200ms max-width ease-out !important;
}
